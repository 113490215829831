<template>
  <static-template>
    <h1>FAQs</h1>
    <ul class="faqs">
      <li v-for="(faq, i) in faqs" :key="i" class="faqs__item">
        <div
          class="faqs__header"
          :class="`faqs__header--uncollapsed': ${visible[i]}`"
        >
          <div class="faqs__title">
            {{ faq.title }}
          </div>
          <div>
            <button @click="toggleFaq(i)" type="button">
              <v-icon color="base">
                {{ visible[i] ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </button>
          </div>
        </div>
        <div
          v-show="visible[i]"
          class="faqs__content"
          :class="{ 'faqs__content--uncollapsed': visible[i] }"
          v-html="faq.body"
        ></div>
      </li>
    </ul>
  </static-template>
</template>

<script>
export default {
  name: "FaqsPage",
  data() {
    return {
      visible: [false, false, false, false, false, false, false],
    };
  },
  computed: {
    faqs() {
      return [
        {
          title: this.$t("title1"),
          body: this.$t("body1"),
        },
        {
          title: this.$t("title2"),
          body: this.$t("body2"),
        },
        {
          title: this.$t("title3"),
          body: this.$t("body3"),
        },
        {
          title: this.$t("title4"),
          body: this.$t("body4"),
        },
        {
          title: this.$t("title5"),
          body: this.$t("body5"),
        },
        {
          title: this.$t("title6"),
          body: this.$t("body6"),
        },
        {
          title: this.$t("title7"),
          body: this.$t("body7"),
        },
      ];
    },
  },
  methods: {
    toggleFaq(faq) {
      this.visible[faq] = !this.visible[faq];
      this.visible = [...this.visible];
    },
  },
};
</script>

<style lang="scss" scoped>
.faqs {
  padding: 0;
  margin: 48px auto 24px;
  &__item {
    margin: 48px 0;
    list-style-type: none;
  }
  &__header {
    border-bottom: 1px solid;
    display: flex;
    justify-content: space-between;
    transition: border-bottom 1.2s linear;
    &--uncollapsed {
      border-bottom: 0;
    }
  }
  &__title {
    font-size: var(--font-size-4);
  }
  &__content {
    margin-top: 12px;
  }
}

.faqs__content {
  &--uncollapsed {
    animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  ul {
    list-style-type: disc;
    padding-left: 24px;
  }
  li {
    margin-bottom: 24px;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
